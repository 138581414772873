import axios from 'axios'
import httpBuilder from './httpBuilder.js'
import Cookies from "js-cookie";
import { eventHub } from "@/libs/eventhub";

const baseClient = axios.create({
    baseURL: process.env.VUE_APP_API_V2_URL
});

let defaultLang = 'vi'

baseClient.interceptors.request.use(
    function (config) {
        let smt = Cookies.get('_smt')
        config.headers["Authorization"] = `Bearer ${smt}`
        config.headers["skey"] = `${process.env.VUE_APP_SSC_KEY}`
        config.headers["accept-language"] = defaultLang
        eventHub.$emit('isLoading')
        return config;
    },
    function (error) {
        eventHub.$emit('isLoading')
        return Promise.reject(error);
    }
);

baseClient.interceptors.response.use(
    response => {
        eventHub.$root.$emit('endLoading')
        return response;
    },
    error => {
        eventHub.$root.$emit('endLoading')
        return Promise.reject(error);
    }
);

export const httpClient = httpBuilder(baseClient)
