<template>
    <div class="position-relative" v-click-outside="hideDropdown">
        <v-text-field :value="value"
                      :label="label"
                      :disabled="disabled"
                      :class="customClass"
                      :outlined="outlined"
                      :dense="dense"
                      :hide-details="hideDetails"
                      :single-line="singleLine"
                      @input="updateValue"
                      @focus="showDropdown"
                      @keyup="getSuggestion"
        ></v-text-field>
        <div class="position-absolute result-list" v-if="dropdown">
            <template v-if="suggestions.length > 0">
                <div class="result-item" :class="{'selected-item': selected.id && selected.id === suggestion.id}"
                     v-for="(suggestion, sindex) in suggestions" :key="`s_${sindex}_${suggestion.phone}`" @click="selectItem(suggestion)">
                    <div>{{ suggestion.name }}</div>
                    <div>{{ suggestion.phone }}</div>
                    <div class="text-capitalize">{{ addressTxt(suggestion) }}</div>
                </div>
            </template>
            <template v-else>
                <div class="result-item font-italic">
                    <div>{{ !value ? $t('labels.enter_text_for_search') : $t('labels.no_result_and_auto_create') }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {debounce} from "lodash/function";
import {httpClient} from "@/libs/http";

export default {
    name: "SelectSuggestBuyer",
    props: {
        value: {
            type: [Number, String, Array, Object],
            default: () => null
        },
        label: {
            type: String,
            default: () => 'POS'
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        outlined: {
            type: Boolean,
            default: () => true
        },
        dense: {
            type: Boolean,
            default: () => true
        },
        hideDetails: {
            type: Boolean,
            default: () => true
        },
        singleLine: {
            type: Boolean,
            default: () => true
        },
        customClass: {
            type: String,
            default: () => null
        }
    },
    data: () => ({
        dropdown: false,
        selected: {},
        suggestions: []
    }),
    model: {
        prop: ['value'],
        event: 'change'
    },
    mounted() {
    },
    methods: {
        getSuggestion: debounce(function () {
            httpClient.post('/pos-get-buyers', {phone: this.value}).then(({data}) => {
                this.suggestions = [...data.rows]
            })
        }, 500),
        selectItem(item) {
            this.updateValue(item.phone)
            this.hideDropdown()
        },
        updateValue(val) {
            this.$emit('change', val)
        },
        addressTxt(item) {
            const addr = []
            if (!item.id) {
                return null
            }
            if (item.address) {
                addr.push(item.address)
            }
            if (item.ward_name) {
                addr.push(item.ward_name)
            }
            if (item.county_name) {
                addr.push(item.county_name)
            }
            if (item.city_name) {
                addr.push(item.city_name)
            }
            return addr.join(', ')
        },
        showDropdown() {
            this.dropdown = true
        },
        hideDropdown() {
            this.dropdown = false
        },
    },
}
</script>

<style lang="scss" scoped>
.result-list {
  width: 100%;
  background: #fff;
  z-index: 1;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  max-height: 280px;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;

  .result-item {
    padding: 8px;
    border-bottom: 1px solid #f3f3f3;
    font-size: 12px;

    &:hover {
      background: #efefef;
      cursor: pointer;
    }

    &.selected-item {
      background-color: #1976d2;
      color: #fff;
      font-weight: 600;
    }
  }
}
</style>
