<template>
    <v-autocomplete class="c-input-small" :value="value" :items="filteredOptions" :label="$t('labels.warehouse_1')"
        :disabled="disabled" :class="customClass" :outlined="outlined" :dense="dense" :hide-details="hideDetails"
        :single-line="singleLine" @input="updateValue"></v-autocomplete>
</template>

<script>
export default {
    name: "SelectMaterialWarehouseModel",
    props: {
        value: {
            type: [Number, String, Array, Object],
            default: () => null
        },
        label: {
            type: String,
            default: () => 'Kho'
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        outlined: {
            type: Boolean,
            default: () => true
        },
        dense: {
            type: Boolean,
            default: () => true
        },
        hideDetails: {
            type: Boolean,
            default: () => true
        },
        singleLine: {
            type: Boolean,
            default: () => true
        },
        customClass: {
            type: String,
            default: () => null
        },
        idCity: {
            type: [String, Number],
            default: () => null
        }
    },
    data: () => ({
        options: []
    }),
    model: {
        prop: ['value'],
        event: 'change'
    },
    computed: {
        filteredOptions() {
            if (!this.idCity) {
                return this.options
            }
            return this.options.filter(opt => opt.id_city === this.idCity)
        }
    },
    watch: {
        idCity() {
            const checkMW = this.filteredOptions.find(opt => opt.value === this.value)
            if (!checkMW) {
                this.updateValue(null)
            }
        }
    },
    mounted() {
        const { material_warehouses } = window.me
        this.options = [...material_warehouses].map(p => ({
            value: p.id,
            text: p.code || '',
            id_city: p.id_city || '',
        }))
        if (this.options.length === 1) {
            const val = this.options[0].value
            this.updateValue(val)
        }
    },
    methods: {
        updateValue(val) {
            this.$emit('change', val)
        }
    },
}
</script>

<style scoped></style>