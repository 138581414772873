import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutMain from '../views/layout/Main.vue'
import auth from '../middleware/auth'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/notification',
        component: LayoutMain,
        meta: {
            permissions: [],
            middleware: [auth],
            pageName: 'SSC',
            title: 'SSC',
        },
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: () => import('../views/pages/Dashboard'),
                meta: {
                    permissions: ['pos_dashboard_manager'],
                    middleware: [auth],
                    pageName: '',
                    title: '',
                },
            },

            {
                path: '/notification',
                name: 'Notification',
                component: () => import('../views/pages/Notification.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: 'Thông báo',
                    title: 'Thông báo',
                },
            },
            {
                path: '/employee',
                name: 'Employee',
                component: () => import('../views/pages/employee/Index.vue'),
                meta: {
                    permissions: ['employee_manager'],
                    middleware: [auth],
                    pageName: 'QL. Nhân viên',
                    title: 'QL. Nhân viên',
                },
            },
            /**
             * Account
             */
            {
                path: '/change-password',
                name: 'ChangePassword',
                component: () => import('../views/pages/account/ChangePassword.vue'),
                meta: {
                    permissions: [],
                    modules: [],
                    middleware: [auth],
                    pageName: 'Đổi mật khẩu',
                    title: 'Đổi mật khẩu',
                },
            },
            /**
             * End Account
             */


            /**
             * POS
             */
            {
                path: '/timekeeping',
                name: 'Timekeeping',
                component: () => import('../views/pages/timekeeping/Index.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: 'Chấm công',
                    title: 'Chấm công',
                },
            },
            {
                path: '/material-list',
                name: 'MaterialList',
                component: () => import('../views/pages/material/Index'),
                meta: {
                    permissions: ['material_manager'],
                    middleware: [auth],
                    pageName: 'QL. Hàng',
                    title: 'QL. Hàng',
                },
            },
            {
                path: '/material-uid',
                name: 'MaterialUid',
                component: () => import('../views/pages/material/Uid'),
                meta: {
                    permissions: ['material_uid_manager'],
                    middleware: [auth],
                    pageName: 'QL. UID',
                    title: 'QL. UID',
                },
            },
            {
                path: '/receipt-history',
                name: 'ReceiptHistory',
                component: () => import('../views/pages/material/ReceiptHistory'),
                meta: {
                    permissions: ['material_receipt_history'],
                    middleware: [auth],
                    pageName: 'QL. Nhập',
                    title: 'QL. Nhập',
                },
            },
            {
                path: '/receipt-accessory',
                name: 'ReceiptAccessory',
                component: () => import('../views/pages/material/ReceiptAccessory'),
                meta: {
                    permissions: ['material_receipt_accessory'],
                    middleware: [auth],
                    pageName: 'Nhập phụ liệu',
                    title: 'Nhập phụ liệu',
                },
            },
            {
                path: '/receipt-material',
                name: 'ReceiptMaterial',
                component: () => import('../views/pages/material/ReceiptMaterial'),
                meta: {
                    permissions: ['material_receipt_material'],
                    middleware: [auth],
                    pageName: 'Nhập nguyên liệu',
                    title: 'Nhập nguyên liệu',
                },
            },
            {
                path: '/material-set-position',
                name: 'MaterialSetPosition',
                component: () => import('../views/pages/material/SetPositionFree'),
                meta: {
                    permissions: ['material_set_position'],
                    middleware: [auth],
                    pageName: 'Gán vị trí',
                    title: 'Gán vị trí',
                },
            },
            {
                path: '/material-print-stamp',
                name: 'GoodsPrintStamp',
                component: () => import('../views/pages/material/PrintStamp.vue'),
                meta: {
                    permissions: ['pos_download_goods_stamp'],
                    middleware: [auth],
                    pageName: 'In tem',
                    title: 'In tem',
                },
            },
            {
                path: '/test',
                name: 'Test',
                component: () => import('../views/pages/test/Index.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: 'Test',
                    title: 'Test',
                },
            },
            /**
             * End POS
             */
        ]
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/pages/Register.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/pages/Login.vue'),
    },
    {
        path: '/403',
        name: 'Error403',
        component: () => import('../views/pages/errors/403.vue'),
    }
]


const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({...context, next: nextMiddleware});
    }

    return next();
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || process.env.VUE_APP_TITLE;
    });
});

export default router
